<template>
  <div class="loginBox">
    <div class="loginTitle">紫菲通行证登录</div>
	<div class="loginDiv">
		<input type="text" v-model="mobile" class="userInput" placeholder="请输入紫菲账号"/>
	</div>
	<div class="loginDiv">
		<input type="password" v-model="password" class="pswInput" placeholder="请输入密码"/>
	</div>
	<div class="loginDiv">
		<input type="text" v-model="code" class="imgCodeInput" placeholder="图片验证码"/>
		<img :src="imgCode" class="imgCode" @click="refreshImgCode"/>
	</div>
	
	<van-button class="qdBtn" @click="pswLoginFun" v-preventReClick="1500">确定</van-button>
	<div class="loginbtns">
		<router-link to='/phoneLogin' replace>短信验证登录</router-link>
		<router-link :to='{path:"/forgetpsw",query:{history:"/pswLogin"}}' replace>忘记密码</router-link>
		<router-link :to='{path:"/register",query:{history:"/pswLogin"}}' replace>账号注册</router-link>
	</div>
  </div>
  <!--底部菜单-->
  <MENUE current='login'></MENUE>
</template>

<script>

import { defineComponent } from 'vue';
import request from '@/service/request' 
import {Toast,Button} from "vant"
import { mapGetters } from 'vuex';
import MENUE from '@/components/menue/menue.vue';	
// import preventReClick from'@/util/v-repeat';

export default defineComponent({
  name: 'pswLogin',
  components: {
	  [Button.name]:Button,
	  MENUE
  },
  data(){
	  return{
		  imgCode:'',
		  mobile:'',
		  key:'',
		  password:'',
		  code:''
	  }
  },
  // directives:{
  // 	  preventReClick:preventReClick
  // },
  created(){
	  this.getImgCode();
  },
  methods:{
  	async getImgCode(){
		this.imgCode='';
		var key=localStorage.getItem('key');
		if(key){
			this.key=key;
		}else{
			const {data}=await request.apiAxios({
				method:'get',
				url:request.GETKEY					
			});
			this.key=data.key;
			localStorage.setItem('key',data.key);
		}
  		setTimeout(()=>{
  			this.imgCode=request.baseURL+request.WEBCODE+"&key="+this.key;
  		},0)
  	},
	//刷新验证码
	refreshImgCode(){
		this.getImgCode();
	},
	//密码登录
	pswLoginFun(){
		if(!this.mobile||!this.password||!this.code){
			Toast("请完善信息！");
			return;
		}
		request.apiAxios({
			method: 'post',
			url: request.PSWLOGIN,
			data: {
				username:this.mobile,
				password:this.password,
				code:this.code,
				key:this.key
			}
		}).then((res) => {
			console.log(res);
			if(res.status){
				localStorage.setItem('token',res.data.token);
				localStorage.setItem('userInfo',JSON.stringify(res.data));
				this.$store.dispatch('setToken',res.token);
				Toast("登录成功！");
				this.$router.replace('/');
			}else{
				Toast(res.message);
				this.refreshImgCode();
			}
			
		}).catch((err) => {
			console.log(err);
			debugger;
		})
	}
  },
});
</script>

<style lang="less" scoped="scoped">
	
</style>
